import { useQuery } from '@tanstack/react-query';
import { IMonitorWithLocation } from 'app/business-logic/domain-models/Monitoring';
import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { fetchMapMonitors, FetchMapMonitorsParams } from 'app/business-logic/services/monitor-service';
import { useAppSelector } from 'app/redux/store';
import { useHasAccess } from './use-has-access';

const FIFTEEN_MINUTES = 1000 * 60 * 15;
const INITIAL_DATA: IMonitorWithLocation[] = [];

export const useMonitors = (options?: FetchMapMonitorsParams) => {
  const { id: facilityId } = useAppSelector(state => state.profile.facility);
  const hasMonitoringAccess = useHasAccess()(KnownAccessControls.app.monitoring._);
  const { data, refetch, isSuccess, isFetching } = useQuery({
    queryKey: [QUERY_KEY.mapMonitors, options],
    queryFn: () => fetchMapMonitors(options),
    enabled: !!facilityId && hasMonitoringAccess,
    refetchOnWindowFocus: false,
    staleTime: FIFTEEN_MINUTES,
  });
  return {
    data: data ?? INITIAL_DATA,
    refetch,
    isSuccess,
    isFetching,
  };
};
