import { monitorHasSecondlyNoiseConfig } from 'app/business-logic/helpers/monitorHasSecondlyNoiseConfig';
import { useEffect, useMemo } from 'react';
import { LogManager } from 'core/logging/LogManager';
import { IMonitorWithSecondlyNoiseConfig } from 'app/business-logic/domain-models/Monitoring';
import { useMonitors } from 'app/hooks/useMonitors';
import lodash from 'lodash';
import { useMonitorLocations } from 'app/hooks/useMonitorLocations';

const logger = LogManager.getLogger('useNoiseMonitors');

export function useNoiseMonitors() {
  const { data: monitors } = useMonitors();
  const locations = useMonitorLocations();
  const secondlyNoiseMonitors = useMemo(
    () =>
      monitors.flatMap(monitor => {
        if (!monitorHasSecondlyNoiseConfig(monitor)) return [];
        const { location } = locations.find(l => l.id === monitor.id) ?? {};
        if (!location) return [];
        return {
          ...monitor,
          location,
        };
      }),
    [locations, monitors]
  );
  const { uniqueConfigurations, duplicateConfigurations } = useMonitorsWithUniqueConfig(secondlyNoiseMonitors);

  useLogWarnings(duplicateConfigurations);

  return uniqueConfigurations;
}

function useMonitorsWithUniqueConfig(secondlyNoiseMonitors: Array<IMonitorWithSecondlyNoiseConfig>) {
  return useMemo(() => {
    const uniqueConfigurations: Array<IMonitorWithSecondlyNoiseConfig> = [];
    const duplicateConfigurations: Array<{
      monitor: IMonitorWithSecondlyNoiseConfig;
      firstInstance: IMonitorWithSecondlyNoiseConfig;
    }> = [];
    secondlyNoiseMonitors.forEach((monitor, index, arr) => {
      const firstIndexWithDeviceId = arr.findIndex(
        m => m.omnisConfigJson.config.deviceId === monitor.omnisConfigJson.config.deviceId
      );
      const firstInstance = arr[firstIndexWithDeviceId];
      if (!firstInstance) return;
      const isUnique = firstIndexWithDeviceId === index;
      if (isUnique) {
        uniqueConfigurations.push(monitor);
        return;
      }
      duplicateConfigurations.push({
        monitor,
        firstInstance,
      });
    });
    return {
      uniqueConfigurations,
      duplicateConfigurations,
    };
  }, [secondlyNoiseMonitors]);
}

const { debounce } = lodash;
const warn = debounce(logger.warn);

function useLogWarnings(
  duplicateConfigurations: ReturnType<typeof useMonitorsWithUniqueConfig>['duplicateConfigurations']
) {
  const warnings = useMemo(
    () =>
      duplicateConfigurations
        .map(
          ({ monitor, firstInstance }) =>
            `Process "${monitor.name}" (${monitor.id}) has been configured with the same Omnis device ID as "${firstInstance.name}" (${firstInstance.id})`
        )
        .join('\n'),
    [duplicateConfigurations]
  );

  useEffect(() => {
    if (warnings.length) {
      warn(warnings);
    }
  }, [warnings]);
}
