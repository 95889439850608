import { useI18n } from 'core/hooks/useI18n';
import { DefaultUnauthorisedMessage } from './UnauthorisedMessage.styles';
import { Icon } from 'app/components/icon';

export const UnauthorisedMessage = () => {
  const { l10n } = useI18n('app');
  return (
    <DefaultUnauthorisedMessage>
      <Icon.Warning width="20px" height="20px" />
      {l10n('unauthorised')}
    </DefaultUnauthorisedMessage>
  );
};
