import { DatePickerProps, TimePrecision } from '@blueprintjs/datetime';
import { useI18n } from 'core/hooks/useI18n';
import React, { useCallback, useState } from 'react';
import { i18nInstance } from 'i18n';
import { localeUtils, DEFAULT_LOCALE } from './localeUtils';
import { ButtonBar, Cancel, Confirm, DatePickerWrapper, StyledDatePicker } from './StyledComponents';
import { getLocalDate } from 'app/helpers/getLocalDate';
import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { DateTime } from 'luxon';
import { useAppSelector } from 'app/redux/store';

type IProps = {
  initialValue: number | null;
  onValueChange: (millis: number) => void;
  onCancel: () => void;
  dateOnly?: boolean;
} & DatePickerProps;

export const DateTimeSelector = (props: IProps) => {
  const { initialValue, onValueChange, onCancel, dateOnly, ...datePickerProps } = props;
  const { l10n } = useI18n(['app/components', 'app']);
  const timePickerProps = dateOnly ? undefined : { useAmPm: true };
  const localTime = useLocalTimeCallback();
  const { ianaTimezone } = useAppSelector(state => state.profile.facility);

  const [date, setDate] = useState<Date>(() => {
    if (initialValue === null || initialValue === undefined) {
      return getLocalDate(localTime());
    }
    return getLocalDate(localTime(initialValue));
  });

  const handleDateChange = useCallback((selectedDate: Date) => {
    setDate(selectedDate);
  }, []);

  const handleOkClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    onValueChange(DateTime.fromJSDate(date).setZone(ianaTimezone, { keepLocalTime: true }).toMillis());
  };

  const handleCancelClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    onCancel();
  };

  return (
    <DatePickerWrapper>
      <StyledDatePicker
        timePrecision={TimePrecision.MINUTE}
        timePickerProps={timePickerProps}
        defaultValue={date}
        onChange={handleDateChange}
        dayPickerProps={{ firstDayOfWeek: 1 }}
        locale={i18nInstance.language ?? DEFAULT_LOCALE}
        localeUtils={localeUtils}
        {...datePickerProps}
      />

      <ButtonBar>
        <Confirm text={l10n('app:action.confirm')} onClick={handleOkClick} />
        <Cancel text={l10n('app:action.cancel')} onClick={handleCancelClick} />
      </ButtonBar>
    </DatePickerWrapper>
  );
};
