import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseMapLayerType, ILayers, MapTimeSource } from './types';
import IMonitorMarker from 'app/business-logic/domain-models/map/IMonitorMarker';
import { facilityChanged } from '../profile/facility/actions';
import { resetStore } from '../actions';

export type MapState = typeof INITIAL_STATE;

export const INITIAL_STATE = {
  /**
   * How is the time being provided to the map?
   */
  mapTimeSource: MapTimeSource.realTime,

  /**
   * The current time shown on the map
   */
  mapTimeMillis: Date.now(),

  /**
   * The google maps base layer (road/satellite)
   */
  baseMapType: BaseMapLayerType.satellite,

  /**
   * Whether the satellite map is displayed in grayscale
   */
  greyscaleMap: true,

  /**
   * The list of map layer contextual information that is turned on/off.
   */
  layers: {
    debug: {
      visible: false,
    },
    monitorNames: {
      visible: true,
    },
    inactiveMonitors: {
      visible: false,
    },
    sourceAreas: {
      visible: true,
    },
    activityLocations: {
      visible: true,
    },
    influenceArcs: {
      visible: true,
      groupName: 'diagnostics',
    },
    kmlOverlays: {
      visible: true,
    },
    customImageOverlays: {
      visible: true,
    },
    monitorsInTrajectories: {
      visible: true,
    },
    domainBoundaries: {
      visible: true,
    },
    emissionSources: {
      visible: true,
    },
  } as ILayers,
};

export const slice = createSlice({
  name: 'map',
  initialState: INITIAL_STATE,
  reducers: {
    mapBaseLayerChanged: (state, { payload }: PayloadAction<BaseMapLayerType>) => {
      return {
        ...state,
        baseMapType: payload,
      };
    },

    mapGrayscaleChanged: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        greyscaleMap: payload,
      };
    },

    mapTimeChanged: (state, { payload }: PayloadAction<number | undefined>) => {
      if (!payload || payload === state.mapTimeMillis) return state;

      return {
        ...state,
        mapTimeMillis: payload,
      };
    },

    mapTimeSourceChanged: (state, { payload }: PayloadAction<MapTimeSource>) => {
      if (state.mapTimeSource === payload) return state;

      return {
        ...state,
        mapTimeSource: payload,
      };
    },

    layersChanged: (state, { payload }: PayloadAction<keyof ILayers>) => {
      if (!payload) return state;
      const targetLayer = state?.layers[payload];
      const visible = !targetLayer?.visible;
      const layers = {
        ...state?.layers,
        [payload]: {
          ...targetLayer,
          visible,
        },
      };

      type Key = keyof typeof layers;

      // make everything else in this group not visible
      if (!!targetLayer?.groupName && visible) {
        (Object.keys(layers) as Key[]).forEach(key => {
          if (key === payload) return;
          if (layers[key]?.groupName !== targetLayer.groupName || !layers[key]?.visible) return;

          layers[key] = {
            ...layers[key],
            visible: false,
          };
        });
      }

      return {
        ...state,
        layers,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(facilityChanged.type, state => {
      return {
        ...state,
        monitorMarkers: [] as IMonitorMarker[],
      };
    });

    builder.addCase(resetStore.type, () => {
      return INITIAL_STATE;
    });
  },
});
