import { TemporalDataSeries } from 'app/business-logic/domain-models/Monitoring';

export const CHUNK_EXPIRY_TIME_MILLIS = 60000;

export default class SensorDataChunk {
  protected temporalData!: TemporalDataSeries;
  private lastAccessedMillis: number;

  public startMillis!: number;
  public endMillis!: number;

  constructor(data?: TemporalDataSeries) {
    this.lastAccessedMillis = new Date().valueOf();
    if (!data) return;
    this.temporalData = data;
  }

  public get data() {
    this.lastAccessedMillis = new Date().valueOf();
    return this.temporalData;
  }

  /**
   * Adds a point to the beginning of the chunk and extends the start millis for the chunk to cover this point.
   *
   * @param timeMillis The data point time
   * @param value The data point value
   */
  public unshift(timeMillis: number, value: number) {
    this.temporalData.unshift([timeMillis, value]);
    this.startMillis = timeMillis;
  }
}
