import { AccessControl } from 'app/business-logic/security/KnownAccessControls';
import { useHasAccess } from 'app/hooks/use-has-access';
import { ReactNode, PropsWithChildren } from 'react';
import { UnauthorisedMessage } from './unauthorised-message';

type AccessControlGateProps = {
  requires: AccessControl | AccessControl[];
  fallback?: ReactNode;
};

export const AccessControlGate = ({
  requires,
  fallback = <UnauthorisedMessage />,
  children,
}: PropsWithChildren<AccessControlGateProps>) => {
  const hasAccess = useHasAccess()(requires);

  if (!hasAccess) {
    if (!fallback) return null;
    return <>{fallback}</>;
  }

  return <>{children}</>;
};
