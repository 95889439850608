import { resetStore } from 'app/redux/actions';
import { useAppDispatch, persistor } from 'app/redux/store';
import { LogManager } from 'core/logging/LogManager';
import { useCallback } from 'react';
import { clearCache as clearSensorDataCache } from '../../sensor-data/SensorDataManager';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { useQueryClient } from '@tanstack/react-query';

const logger = LogManager.getLogger('useClearCache');

export const useClearCache = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  return useCallback(() => {
    logger.debug('[Redux] Resetting the store');
    dispatch(resetStore());
    logger.debug('[Redux] Purging the persisted store');
    persistor.purge();
    logger.debug('[Omnis] Clearing the sensor data cache');
    clearSensorDataCache();
    logger.debug('[React Query] Clearing the query client cache');
    queryClient.clear();
    logger.debug('[Apollo] Stopping any active queries');
    ApolloProvider.global().stop();
    logger.debug('[Apollo] Clearing the cache');
    ApolloProvider.global().clearStore();
  }, [dispatch, queryClient]);
};
