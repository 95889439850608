import { IMeasurement, ITemporalValue, TemporalDataSeries } from 'app/business-logic/domain-models/Monitoring';
import { LogManager } from 'core/logging/LogManager';
import Guid from 'core/types/Guid';
import { DateTime } from 'luxon';

import cache from './SensorDataCache';
import SensorDataChunk from './SensorDataChunk';
import { isStale } from '../monitor-service/helpers/isStale';
import { fetchSpotDataForSensors } from 'app/business-logic/services/monitor-service/fetchSensorData';

const logger = LogManager.getLogger('SensorDataManager');

export enum CacheStrategy {
  cacheOnly,
  cacheThenNetwork,
}

export async function getSpotDataForSensors(
  sensorIds: Guid[],
  spotTimeMillis: number,
  cacheStrategy = CacheStrategy.cacheThenNetwork
): Promise<IMeasurement[] | null> {
  if (!(sensorIds && sensorIds.length && spotTimeMillis)) {
    return null;
  }

  const result: IMeasurement[] = [];

  const sensorsToFetch: Guid[] = [];
  const sensorsWithCachedData = new Map<Guid, SensorDataChunk>();

  await Promise.all(
    sensorIds.map(async sensorId => {
      const cacheResult = await getSpotDataFromCache(
        sensorId,
        spotTimeMillis,
        cacheStrategy === CacheStrategy.cacheOnly
      );
      if (cacheResult) {
        if (cacheResult instanceof SensorDataChunk) {
          sensorsToFetch.push(sensorId);
          sensorsWithCachedData.set(sensorId, cacheResult);
        } else {
          result.push(cacheResult);
        }
      } else {
        sensorsToFetch.push(sensorId);
      }
    })
  );

  if (sensorsToFetch.length && cacheStrategy !== CacheStrategy.cacheOnly) {
    let sensorIdList = [...sensorsToFetch]
      .sort((left, right) => left.localeCompare(right))
      .slice(0, 2)
      .join(', ');

    if (sensorsToFetch.length > 2) {
      sensorIdList += '...';
    }

    logger.debug(`Spot data cache -> hit#: ${result.length}, missed#: ${sensorsToFetch.length} - ${sensorIdList}`);

    const remainingData = await fetchSpotDataForSensors(sensorsToFetch, DateTime.fromMillis(spotTimeMillis));
    remainingData.forEach(dataPoint => {
      result.push(dataPoint);

      const chunk = sensorsWithCachedData.get(dataPoint.sensorId);
      if (chunk && typeof dataPoint.value === 'number') {
        chunk.unshift(dataPoint.timeMillis, dataPoint.value);
      }
    });
  }

  return result;
}

async function getSpotDataFromCache(
  sensorId: Guid,
  spotTimeMillis: number,
  useClosest: boolean
): Promise<IMeasurement | SensorDataChunk | null> {
  if (!(sensorId && spotTimeMillis)) return null;

  const entry = cache.get(sensorId, false);
  if (!entry) return null;

  await entry.lock;

  const dataPoint = getSpotDataPoint() || getClosestSpotDataPoint();
  if (!dataPoint) return null;

  return {
    sensorId,
    isStale: isStale({
      dataTimeMillis: dataPoint.timeMillis,
      facilityTimeMillis: spotTimeMillis,
      staleDataTimeoutSeconds: entry.staleDataTimeoutMillis,
    }),
    ...dataPoint,
  };

  ////////////////////

  function getSpotDataPoint(): ITemporalValue | null {
    for (const chunk of entry?.chunks ?? []) {
      if (spotTimeMillis < chunk.startMillis || spotTimeMillis > chunk.endMillis) {
        continue;
      }

      const spotDataIndex = getIndexOfClosestTime(chunk.data, spotTimeMillis);

      if (spotDataIndex === null) continue;

      const [timeMillis, value] = chunk.data[spotDataIndex] ?? ([] as number[]);

      if (typeof timeMillis !== 'number' || typeof value !== 'number') continue;

      // If the time for the data point that we got back from the chunk is after our spot time, it means that
      // the chunk probably spans over sampling rate intervals and should be extended backwards. For example,
      // if we have a 10 minute sampling rate, and we are looking at a chunk that goes from 10:15 - 11:15, and
      // we ask for the value at 10:18, the chunk would give us back the value at 10:20 which is its first entry.
      // In this example, we need to go and fetch the spot data point for 10:18 from the server - this will
      // (ideally) give us back the 10:10 point, which we can add to this chunk.
      if (timeMillis > spotTimeMillis) continue;

      // Otherwise, this is the the data point to return.
      return { timeMillis, value };
    }
    return null;
  }

  function getClosestSpotDataPoint(): ITemporalValue | null {
    if (!useClosest) return null;

    let latestChunkBeforeSpotTime: SensorDataChunk | null = null;

    for (const chunk of entry?.chunks ?? []) {
      // We know that there are no chunks that span over the spot time (otherwise we would have found a better spot
      // data point already) so if we find a chunk that starts on/after the spot time then we can stop looking.
      if (chunk.startMillis > spotTimeMillis) break;
      latestChunkBeforeSpotTime = chunk;
    }

    if (!latestChunkBeforeSpotTime) return null;

    const lastDataPointInChunk = latestChunkBeforeSpotTime.data[latestChunkBeforeSpotTime.data.length - 1];
    const [timeMillis, value] = lastDataPointInChunk ?? ([] as number[]);

    if (typeof timeMillis !== 'number' || typeof value !== 'number') return null;

    return { timeMillis, value };
  }
}

/**
 * Binary search to find the index of the data point whose time is closest to the target time.
 */
function getIndexOfClosestTime(data: TemporalDataSeries, targetTime: number, resolution = -1, startIndex = 0) {
  let start = startIndex,
    end = data.length - 1,
    midpoint: number | null = null;

  while (start <= end) {
    midpoint = Math.floor((start + end) / 2);

    const time = data[midpoint]?.[0];

    if (time === targetTime) return midpoint;

    if (typeof time === 'number' && time < targetTime) {
      start = midpoint + 1;
    } else {
      end = midpoint - 1;
    }
  }

  // If we get this far, we couldn't find the exact time, so the midpoint represents the closest possible time. Use
  // the 'resolution' parameter to work out if we should return the time before (-1) or after (1) the requested time.
  if (resolution) {
    if (typeof midpoint !== 'number') {
      console.error('Error finding midpoint');
      return null;
    }

    const midpointTime = data[midpoint];

    if (typeof midpointTime === 'number') {
      if (resolution < 0 && midpointTime > targetTime && midpoint > startIndex) return midpoint - 1;
      if (resolution > 0 && midpointTime < targetTime && midpoint < data.length - 1) return midpoint + 1;
    }
  }

  return midpoint;
}

export function clearCache() {
  cache.clear();
}
