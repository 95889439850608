import { gql } from '@apollo/client';

export const FETCH_MONITOR_LOCATIONS = gql`
  query FetchMonitorLocations($processFilter: ProcessFilter, $monitorLocationFilter: MonitorLocationFilter) {
    facility {
      processes(filter: $processFilter) {
        id
        name
        ... on Monitor {
          locationHistory(filter: $monitorLocationFilter) {
            latitude
            longitude
            elevation
            activeFromTime
          }
        }
      }
    }
  }
`;
