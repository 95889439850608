import { styled } from 'styled-components';

export const DefaultUnauthorisedMessage = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  width: 100%;
  text-align: center;
`;
